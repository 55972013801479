"use client";

import axios from "axios";
import { logger } from "../../rollbar";
import { getIdToken } from "../../firebaseProvider";

const BASE_URL = process.env.NEXT_PUBLIC_API_ENDPOINT_URL;

function checkError(error = null) {
  if (error && error.response && error.response.status === 401) {
    window.location.href = "/"; // Reset to home Screen if token not found
  }
}

const axiosPrivateInstanceApiClose = axios.create();
const apiCloseConfig = {
  baseURL: BASE_URL,
  method: "POST",
  url: "/api/close", // Authenticated(closed) Apis Routes
};

/* closeApi shall be used for all the authenticated api calls */
export async function closeApi({ data, extraHeaders }) {
  try {
    const token = await getIdToken();
    apiCloseConfig.headers = { Authorization: `Bearer ${token}` };

    if (extraHeaders) {
      apiCloseConfig.headers = { ...apiCloseConfig.headers, ...extraHeaders };
    }

    const response = await axiosPrivateInstanceApiClose.request({ ...apiCloseConfig, data });

    return response;
  } catch (error) {
    logger.error(error);
    // checkError(error);
    return null;
  }
}

const axiosPrivateInstanceApiCloseUpload = axios.create();
const apiCloseUploadConfig = {
  baseURL: BASE_URL,
  method: "POST",
  url: "/api/closeUpload",
  headers: {
    Authorization: "",
    "Content-Type": "multipart/form-data",
  },
};

/* closeApiUpload shall be used for all the authenticated api calls where files are uploaded and queries are made */
export async function closeApiUpload({ data, extraHeaders }) {
  try {
    const token = await getIdToken();
    apiCloseUploadConfig.headers.Authorization = `Bearer ${token}`;

    if (extraHeaders) {
      apiCloseUploadConfig.headers = {
        ...apiCloseUploadConfig.headers,
        ...extraHeaders,
      };
    }

    const response = await axiosPrivateInstanceApiCloseUpload.request({
      ...apiCloseUploadConfig,
      data,
    });

    return response;
  } catch (error) {
    logger.error(error);
    checkError(error);
    return null;
  }
}

const axiosPublicInstanceApiOpen = axios.create();
const apiOpenConfig = {
  baseURL: BASE_URL,
  method: "POST",
  url: "/api/open",
};

/* openApi shall be used for all the unauthenticated api calls */
export async function openApi({ data, extraHeaders }) {
  if (extraHeaders) {
    apiOpenConfig.headers = { ...extraHeaders };
  }

  try {
    const response = await axiosPublicInstanceApiOpen.request({
      ...apiOpenConfig,
      data,
    });

    return response;
  } catch (error) {
    logger.error(error);
    checkError(error);
    return null;
  }
}
