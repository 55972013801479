import Rollbar from "rollbar";

export const rollbarConfig = {
  accessToken: "efc30e3fba4446e5ae6d6d733a7cdc45",
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "1.0.0",
        source_map_enabled: true,
      },
    },
  },
};

const RollbarObj = new Rollbar(rollbarConfig);

export const logger = {
  info: (...args) => {
    RollbarObj.info(args);
  },
  warn: (...args) => {
    RollbarObj.warn(args);
  },
  error: (...args) => {
    RollbarObj.error(args);
  },
};
