import { queryCloseApi } from "../utils/apiResolvers";

export const getUserKycStatus = async () => {
  const result = await queryCloseApi({
    queryTemplate: "get_user_kyc",
    queryJsonParams: {},
  });

  return result;
};
