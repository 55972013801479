/* eslint-disable max-len */
import { closeApi, closeApiUpload } from "./resolver";
import { logger } from "../../rollbar";

const queries = {
  update_aadhaar_details: {
    query: "mutation update_aadhaar_details ($data: UpdateAadhaarDetailsType!){ update_aadhaar_details(data: $data) { hasError title message userErrors data }}",
    response: (response) => response.data.update_aadhaar_details,
  },
  update_rental_agreement: {
    query: "mutation update_rental_agreement{ update_rental_agreement { hasError title message userErrors data }}",
    response: (response) => response.data.update_rental_agreement,
  },
  update_gas_bill: {
    query: "mutation update_gas_bill{ update_gas_bill { hasError title message userErrors data }}",
    response: (response) => response.data.update_gas_bill,
  },
  update_voter_id: {
    query: "mutation update_voter_id{ update_voter_id { hasError title message userErrors data }}",
    response: (response) => response.data.update_voter_id,
  },
  update_pan: {
    query: "mutation update_pan{ update_pan { hasError title message userErrors data }}",
    response: (response) => response.data.update_pan,
  },
  update_house_ownership_document: {
    query: "mutation update_house_ownership_document{ update_house_ownership_document { hasError title message userErrors data }}",
    response: (response) => response.data.update_house_ownership_document,
  },
  get_document: {
    query: "query get_document ($data: GetDocumentType!){ get_document(data: $data) { hasError title message userErrors}}",
    response: (response) => response.data.get_document,
  },
  get_delivery_address: {
    query: "query get_delivery_address { get_delivery_address { hasError title message userErrors data { houseNo floorNumber apartmentOrStreet landmark city pin } }}",
    response: (response) => response.data.get_delivery_address,
  },
  get_accommodation_info: {
    query: "query get_accommodation_info {  get_accommodation_info { hasError title message userErrors data { accommodationType } } }",
    response: (response) => response.data.get_accommodation_info,
  },
  update_accommodation_info: {
    query: "mutation update_accommodation_info ($data: UpdateAccommodationInfoType!){ update_accommodation_info(data: $data) { hasError title message userErrors data }}",
    response: (response) => response.data.update_accommodation_info,
  },
  update_delivery_address: {
    query: "mutation update_delivery_address ($data: UpdateDeliveryAddressType!) { update_delivery_address( data: $data ) { hasError title message userErrors data }}",
    response: (response) => response.data.update_delivery_address,
  },
  get_user_kyc: {
    query: "query get_user_kyc{ get_user_kyc { hasError title message userErrors data { status documentsEvaluations contactsEvaluations } }}",
    response: (response) => response.data.get_user_kyc,
  },

  update_user_kyc_submission_status: {
    query: "mutation update_user_kyc_submission_status ($data: UpdateUserKycSubmissionStatusType!) { update_user_kyc_submission_status( data: $data ) { hasError title message userErrors data }}",
    response: (response) => response.data.update_user_kyc_submission_status,
  },
  update_document: {
    query: "mutation update_document ($data:UpdateDocumentInputType!) { update_document( data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_document,
  },
  update_user_contact_info: {
    query: "mutation update_user_contact_info($data:UpdateUserContactInfoInputType!) { update_user_contact_info(data:$data) {hasError title message userErrors data }}",
    response: (response) => response.data.update_user_contact_info,
  },
  update_user_extra_contact_info: {
    query: "mutation update_user_extra_contact_info($data:UpdateUserExtraContactInfoInputType!) { update_user_extra_contact_info(data:$data) { hasError title message userErrors data }}",
    response: (response) => response.data.update_user_extra_contact_info,
  },
};

const kycStatus = {
  isKycStatusSaved: false,
  isKycStatusUpdateInProcess: false,
};

async function updatePendingStatus() {
  if (kycStatus.isKycStatusSaved) {
    kycStatus.isKycStatusUpdateInProcess = false;
    return;
  }

  const USER_KYC_STATUS_KEY = "user_kyc_status";

  const storedStatus = localStorage.getItem(USER_KYC_STATUS_KEY);
  if (storedStatus) {
    kycStatus.isKycStatusSaved = true;
    kycStatus.isKycStatusUpdateInProcess = false;
    return;
  }

  try {
    kycStatus.isKycStatusUpdateInProcess = true;

    // eslint-disable-next-line no-use-before-define
    const response = await queryCloseApi({
      queryTemplate: "update_user_kyc_submission_status",
      queryJsonParams: { status: "SUBMISSION_PENDING" },
    });

    if (!response?.hasError) {
      localStorage.setItem(USER_KYC_STATUS_KEY, "SUBMISSION_PENDING");
      kycStatus.isKycStatusSaved = true;
    }
  } catch (error) {
    logger.error(error);
    throw new Error(error);
  } finally {
    kycStatus.isKycStatusUpdateInProcess = false;
  }
}

export default async function queryCloseApi({
  queryTemplate,
  queryJsonParams,
  filesJsonPayload = {},
}) {
  if (!queryTemplate || !queries[queryTemplate]) {
    throw new Error("ERROR: Invalid parameter \"queryTemplate\" provided");
  }

  const data = {};

  let isDataToBeUploaded = false;
  if (filesJsonPayload && Object.keys(filesJsonPayload).length) {
    isDataToBeUploaded = true;
    const keys = Object.keys(filesJsonPayload);
    for (let i = 0; i < keys.length; i++) {
      const payload = filesJsonPayload[keys[i]];
      if (payload instanceof Blob || payload instanceof File || payload instanceof FileList) {
        data[keys[i]] = payload;
      } else {
        throw new Error("ERROR: Invalid parameter \"filesJsonPayload\" provided");
      }
    }
  }

  data.query = queries[queryTemplate].query;
  if (queryJsonParams) {
    data.variables = { data: queryJsonParams };
  }

  if (!kycStatus.isKycStatusSaved && !kycStatus.isKycStatusUpdateInProcess) {
    updatePendingStatus();
  }
  // Set kyc Status to submission_pending when user logs in for first time

  let response = null;
  if (isDataToBeUploaded) {
    response = await closeApiUpload({
      // uploads files + queries
      extraHeader: {},
      data,
    });
  } else {
    response = await closeApi({
      extraHeader: {},
      data,
    });
  }

  if (response && response.data) {
    return queries[queryTemplate].response(response.data);
  }

  return response;
}
