import React from "react";
import Button from "@mui/material/Button";
import CircularLoader from "./CircularLoader";

export function CommonButton({ children, sx, ...props }) {
  return (
    <Button color="primary" sx={{ borderRadius: 5, ...{ ...sx } }} {...props}>
      {children}
    </Button>
  );
}

export const SubmitButton = ({ text, sx, loading, ...props }) => (
  <Button color="primary" variant="contained" type="submit"
    sx={{ borderRadius: 5, mt: 2, width: 75, height: 40, fontSize: 10, ...{ ...sx } }} {...props}>
    {loading ? <CircularLoader/> : text}
  </Button>
);
