import React from "react";

const WelcomeIcons = () => (
  <svg
    width="332"
    height="182"
    viewBox="0 0 332 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_69_164)">
      <path
        d="M50.5046 155.511C43.1527 160.992 41.0691 170.635 41.0691 170.635C41.0691 170.635 50.9053 171.392 58.2572 165.911C65.6091 160.431 67.6927 150.788 67.6927 150.788C67.6927 150.788 57.8565 150.031 50.5046 155.511Z"
        fill="#F1F1F1"
      />
      <path
        d="M53.5672 158.085C50.0338 166.546 41.1844 170.907 41.1844 170.907C41.1844 170.907 38.0639 161.548 41.5973 153.086C45.1307 144.625 53.98 140.264 53.98 140.264C53.98 140.264 57.1005 149.623 53.5672 158.085Z"
        fill="#F1F1F1"
      />
      <path
        d="M265.088 133.623C264.307 128.361 264.1 123.03 264.472 117.723L265.088 108.941C261.697 115.689 262.463 124.57 265.088 133.623V133.623Z"
        fill="#CACACA"
      />
      <path
        d="M267.732 135.092C267.356 131.952 267.257 128.785 267.436 125.628L267.732 120.4C266.101 124.417 266.47 129.704 267.732 135.092Z"
        fill="#CACACA"
      />
      <path
        d="M279.487 141.557V144.495C279.486 144.729 279.393 144.952 279.228 145.117C279.063 145.282 278.839 145.376 278.606 145.377H277.136C277.098 145.377 277.06 145.384 277.024 145.399C276.988 145.414 276.956 145.435 276.928 145.463C276.901 145.49 276.879 145.522 276.865 145.558C276.85 145.594 276.842 145.632 276.843 145.671V169.765C276.841 169.998 276.748 170.222 276.583 170.387C276.418 170.552 276.195 170.645 275.961 170.647H258.037C257.804 170.646 257.579 170.553 257.414 170.388C257.249 170.223 257.156 169.999 257.156 169.765V145.671C257.155 145.593 257.124 145.518 257.069 145.463C257.014 145.408 256.94 145.377 256.862 145.377H255.099C254.865 145.377 254.641 145.284 254.476 145.118C254.311 144.953 254.218 144.729 254.217 144.495V141.557C254.217 141.441 254.24 141.326 254.284 141.219C254.328 141.112 254.393 141.015 254.475 140.933C254.557 140.851 254.654 140.786 254.761 140.742C254.868 140.698 254.983 140.675 255.099 140.675H278.606C278.839 140.676 279.063 140.769 279.228 140.934C279.393 141.1 279.486 141.323 279.487 141.557Z"
        fill="#F2F2F2"
      />
      <path
        d="M276.869 145.994H257.182V146.875H276.869V145.994Z"
        fill="#E6E6E6"
      />
      <path
        d="M276.843 158.965C270.255 161.691 263.595 161.721 256.862 158.965V152.755C263.344 150.578 270.36 150.578 276.843 152.755V158.965Z"
        fill="#E6E6E6"
      />
      <path
        d="M166.495 64.0115C181.201 64.0115 193.123 52.0898 193.123 37.3837C193.123 22.6776 181.201 10.756 166.495 10.756C151.789 10.756 139.867 22.6776 139.867 37.3837C139.867 52.0898 151.789 64.0115 166.495 64.0115Z"
        fill="#FEB8B8"
      />
      <path
        d="M191.366 14.4115C187.385 7.9469 181.153 3.18336 173.87 1.03787L170.601 3.42339V0.325863C168.852 0.0581722 167.082 -0.0449502 165.314 0.0178526L162.494 2.5697V0.229861C158.318 0.748764 154.295 2.12724 150.679 4.27837C147.062 6.42949 143.931 9.3066 141.481 12.7283C135.357 21.5578 134.323 33.8417 140.347 42.742C142 37.6599 144.008 32.8915 145.662 27.8098C146.961 27.987 148.279 27.9934 149.58 27.8288L151.592 23.1343L152.154 27.6302C158.39 27.0871 167.639 25.8941 173.551 24.8011L172.976 21.352L176.416 24.218C178.227 23.8012 179.302 23.4228 179.213 23.1338C183.61 30.2221 188.991 34.7493 193.387 41.8375C195.057 31.8427 196.979 23.5321 191.366 14.4115Z"
        fill="#2F2E41"
      />
      <path
        d="M223.764 105.858C222.848 100.349 221.904 94.7347 219.344 89.7722C217.661 86.5217 214.99 83.4241 211.389 82.7814C210.68 82.7182 209.983 82.5568 209.318 82.302C208.263 81.7938 194.085 73.7004 191.832 72.2907C189.897 71.0806 186.843 68.8399 185.303 68.8399C183.754 68.8058 177.813 70.1885 154.625 68.3759C154.625 68.3759 148.255 70.8867 143.326 73.9312C143.252 73.882 119.26 86.7655 118.358 86.7316C116.651 86.6597 115.065 87.7527 114.086 89.129C113.108 90.5052 112.649 92.209 112.168 93.8563C117.409 105.526 122.203 117.211 127.444 128.881C127.689 129.32 127.82 129.815 127.823 130.318C127.718 130.841 127.495 131.332 127.171 131.756C124.601 135.884 124.683 141.076 124.963 145.929C125.244 150.781 125.592 155.906 123.423 160.255C122.834 161.449 122.067 162.542 121.506 163.736C120.192 166.44 119.717 175.09 120.485 177.995L216.617 180.749C214.55 175.067 223.764 105.858 223.764 105.858Z"
        fill="#8FE1D7"
      />
      <path
        d="M103.859 129.398C103.667 130.786 103.648 132.192 103.801 133.584L105.179 153.372C105.309 155.236 105.436 157.097 105.611 158.957C105.942 162.568 106.435 166.149 107.048 169.726C107.062 170.233 107.277 170.715 107.645 171.065C108.014 171.414 108.506 171.603 109.014 171.591C115.079 172.874 121.345 172.823 127.535 172.473C136.981 171.946 161.146 170.948 162.623 169.031C164.099 167.113 163.239 164.011 161.313 162.46C159.388 160.909 127.499 157.128 127.499 157.128C127.811 154.659 128.75 152.333 129.642 149.989C131.244 145.831 132.74 141.536 132.768 137.082C132.797 132.628 131.119 127.944 127.47 125.393C124.469 123.298 120.604 122.909 116.947 122.995C114.285 123.072 109.687 122.434 107.257 123.475C105.333 124.322 104.218 127.515 103.859 129.398Z"
        fill="#FBBEBE"
      />
      <path
        d="M111.891 94.2116C111.424 94.7006 111.062 95.2798 110.827 95.9138C106.951 104.648 104.662 114.003 104.067 123.54C104.077 124.005 103.97 124.465 103.755 124.878C103.543 125.152 103.307 125.407 103.05 125.64C102.826 125.906 102.657 126.213 102.551 126.544C102.446 126.875 102.407 127.224 102.436 127.571C102.466 127.917 102.563 128.254 102.723 128.563C102.882 128.871 103.101 129.146 103.367 129.37C103.41 129.407 103.455 129.442 103.501 129.476C104.292 127.987 106.186 127.5 107.869 127.371C115.929 126.734 123.801 130.483 131.885 130.2C131.315 128.229 130.494 126.336 130.029 124.341C127.972 115.486 133.103 105.844 129.957 97.3142C129.329 95.6073 128.279 93.9292 126.625 93.1813C125.94 92.905 125.224 92.7153 124.492 92.6166C122.449 92.2475 118.383 90.67 116.408 91.3029C115.679 91.5381 115.392 92.2234 114.787 92.6376C113.87 93.2239 112.652 93.4395 111.891 94.2116Z"
        fill="#8FE1D7"
      />
      <path
        d="M223.821 123.475C221.391 122.434 216.793 123.072 214.132 122.995C210.474 122.909 206.609 123.298 203.608 125.393C199.959 127.944 198.281 132.628 198.31 137.082C198.338 141.536 199.834 145.831 201.436 149.989C202.328 152.333 203.268 154.659 203.579 157.128C203.579 157.128 171.69 160.909 169.765 162.46C167.84 164.011 166.979 167.113 168.456 169.031C169.932 170.948 215.999 172.874 222.064 171.591C222.572 171.603 223.064 171.414 223.433 171.065C223.801 170.715 224.016 170.233 224.03 169.726C224.643 166.149 225.137 162.568 225.467 158.957C225.642 157.097 225.77 155.236 225.899 153.372L227.277 133.584C227.43 132.192 227.411 130.786 227.219 129.398C226.86 127.515 225.745 124.322 223.821 123.475Z"
        fill="#FBBEBE"
      />
      <path
        d="M216.291 92.6376C215.686 92.2234 215.399 91.538 214.67 91.3029C212.695 90.67 208.629 92.2475 206.586 92.6166C205.854 92.7153 205.138 92.905 204.453 93.1812C202.799 93.9292 201.749 95.6073 201.121 97.3142C197.975 105.844 203.106 115.486 201.049 124.341C200.584 126.336 199.763 128.229 199.193 130.2C207.277 130.483 215.149 126.734 223.209 127.371C224.892 127.5 226.786 127.987 227.577 129.476C227.623 129.442 227.668 129.407 227.711 129.37C227.977 129.146 228.196 128.871 228.355 128.563C228.515 128.254 228.612 127.917 228.642 127.571C228.671 127.224 228.632 126.875 228.527 126.544C228.421 126.213 228.252 125.906 228.028 125.64C227.771 125.407 227.535 125.152 227.323 124.878C227.108 124.465 227.001 124.005 227.011 123.54C226.416 114.003 224.127 104.648 220.251 95.9138C220.016 95.2798 219.654 94.7006 219.187 94.2116C218.426 93.4395 217.208 93.2239 216.291 92.6376Z"
        fill="#8FE1D7"
      />
      <path
        d="M158.609 172.536C162.578 172.536 165.796 169.318 165.796 165.349C165.796 161.38 162.578 158.162 158.609 158.162C154.64 158.162 151.422 161.38 151.422 165.349C151.422 169.318 154.64 172.536 158.609 172.536Z"
        fill="#FBBEBE"
      />
      <path
        d="M174.523 172.536C178.492 172.536 181.709 169.318 181.709 165.349C181.709 161.38 178.492 158.162 174.523 158.162C170.553 158.162 167.336 161.38 167.336 165.349C167.336 169.318 170.553 172.536 174.523 172.536Z"
        fill="#FBBEBE"
      />
      <path
        d="M218.885 181.543H120.149C118.996 181.542 117.891 181.084 117.076 180.268C116.261 179.453 115.802 178.348 115.801 177.195V117.295C115.802 116.142 116.261 115.037 117.076 114.222C117.891 113.407 118.996 112.948 120.149 112.947H218.885C220.038 112.948 221.143 113.407 221.958 114.222C222.773 115.037 223.232 116.142 223.233 117.295V177.195C223.232 178.348 222.773 179.453 221.958 180.268C221.143 181.084 220.038 181.542 218.885 181.543Z"
        fill="#3F3D56"
      />
      <path
        d="M169.278 150.122C170.866 150.122 172.154 148.834 172.154 147.245C172.154 145.656 170.866 144.368 169.278 144.368C167.689 144.368 166.401 145.656 166.401 147.245C166.401 148.834 167.689 150.122 169.278 150.122Z"
        fill="white"
      />
      <path
        d="M331.078 169.461H0V179.635H19.5829V182H311.503V179.635H331.078V169.461Z"
        fill="#F1F1F1"
      />
      <path
        d="M142.009 47.3084C137.731 47.3084 134.262 43.5582 134.262 38.9321V34.7439C134.262 30.1178 137.731 26.3676 142.009 26.3676V47.3084H142.009Z"
        fill="#8FE1D7"
      />
      <path
        d="M191.456 26.3676C195.734 26.3676 199.203 30.1178 199.203 34.7439V38.9321C199.203 43.5582 195.734 47.3084 191.456 47.3084V26.3676Z"
        fill="#8FE1D7"
      />
      <path
        d="M195.515 35.4021H194.286C194.286 18.5473 181.604 4.83485 166.016 4.83485C150.427 4.83485 137.745 18.5473 137.745 35.4021H136.516C136.516 17.8144 149.749 3.50585 166.016 3.50585C182.282 3.50586 195.515 17.8144 195.515 35.4021Z"
        fill="#8FE1D7"
      />
    </g>
    <defs>
      <clipPath id="clip0_69_164">
        <rect width="331.078" height="182" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WelcomeIcons;
