"use client";

import { useRouter } from "next/navigation";
import { useState } from "react";
import { getUserKycStatus } from "../../apiHelpers/getUserKycStatus";
import Welcome from "../Welcome";
import { getCurrentUser } from "../../utils/firebaseProvider";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const getStarted = async () => {
    setLoading(true);
    const response = await getCurrentUser();
    if (!response) {
      setLoading(false);
      router.push("/emailAuth");
    } else {
      const result = await getUserKycStatus();
      setLoading(false);
      const userStatus = result?.data?.status;
      if (userStatus === "INCOMPLETE") {
        router.push("/resubmitKycDetails");
      } else if (!userStatus || userStatus === "SUBMISSION_PENDING") {
        router.push("/Accommodation");
      } else if (["SUBMITTED", "APPROVED", "REJECTED", "RESUBMITTED", "BLOCKED"].indexOf(userStatus) > -1) {
        router.push("/kycStatus");
      }
    }
  };
  return (
    <Welcome loading={loading} getStarted={getStarted} />
  );
}
