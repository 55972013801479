/* eslint-disable max-len */
import { openApi } from "./resolver";

const queries = {
  send_email_verification_link: {
    query:
      "mutation send_email_verification_link ($data: SendEmailVerificationLinkType!){ send_email_verification_link(data: $data) { hasError title message userErrors data }}",
    response: (data) => data.data.send_email_verification_link,
  },
  verify_user_kyc_login: {
    query:
       "mutation verify_user_kyc_login ($data: VerifyUserKycLoginInputType!){ verify_user_kyc_login(data: $data) { hasError title message userErrors data }}",
    response: (data) => data.data.verify_user_kyc_login,
  },
};

export default async function queryOpenApi({ queryTemplate, queryJsonParams }) {
  if (!queryTemplate || !queries[queryTemplate]) {
    throw new Error("ERROR: Invalid parameter \"queryTemplate\" provided");
  }

  const payload = {};

  payload.query = queries[queryTemplate].query;
  payload.variables = { data: queryJsonParams };
  const response = await openApi({
    extraHeader: {},
    data: payload,
  });

  if (response && response.data) {
    return queries[queryTemplate].response(response.data);
  }

  return response;
}
