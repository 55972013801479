// Import the functions you need from the SDKs you need
import { initializeApp, getApp, getApps } from "firebase/app";
import { isSupported, getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { logger } from "./rollbar";

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
};

let app = initializeApp(firebaseConfig);
let analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null));

if (!getApps().length) {
  app = initializeApp(firebaseConfig);
  // Check that `window` is in scope for the analytics module!
  if (typeof window !== "undefined") {
    // Enable analytics. https://firebase.google.com/docs/analytics/get-started
    if ("measurementId" in firebaseConfig) {
      analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null));
      getPerformance(app);
    }
  }
} else {
  getApp();
}

export const auth = getAuth();

export const getCurrentUser = async () => {
  try {
    const { currentUser } = auth || null;
    return currentUser;
  } catch (err) {
    logger.error(err);
    return null;
  }
};

export const getIdToken = async (forceRefresh = false) => {
  try {
    const { currentUser } = auth || {};
    if (currentUser) {
      const idToken = await currentUser.getIdToken(forceRefresh);
      return idToken;
    }
    return currentUser;
  } catch (err) {
    logger.error(err);
    return null;
  }
};

export const fbAnalytics = {
  logEvent: (eventName) => {
    logEvent(analytics, eventName);
    // accommodation_pg
    // accommodation_own_home
    // accommodation_rental_home
    // herd_by_google_ads
    // herd_by_business_card
    // herd_by_referred_by_a_known_person
    // herd_by_social_media
  },
};
