import React from "react";
import Typography from "@mui/material/Typography";
import CircularLoader from "../Common/CircularLoader";
import WelcomeIcons from "../../../assets/Welcome";
import { CommonButton } from "../Common/Buttons";

const Welcome = ({ getStarted, loading }) => (
  <>
    <WelcomeIcons />
    <Typography variant="h4" mt={2}>
      Welcome to Dudes
    </Typography>
    <Typography variant="body1" mt={2}>
      Submit KYC Details
    </Typography>
    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "10px" }}>
      <CommonButton sx={{ width: "50%", height: 40, borderRadius: 20, fontSize: "0.875rem" }}
        fullWidth variant="contained" onClick={() => getStarted({ step: 1 })}>
        {!loading ? "Get Started" : <CircularLoader/> }
      </CommonButton>
    </div>
  </>
);

export default Welcome;
